<template>
	<div class="conditions">
	
	<h3 class="slogan" v-html="$t('conditions.title')"></h3>
	<p v-html="$t('conditions.info.taiga')"></p>
	<p v-html="$t('conditions.info.nif')"></p>
	<p v-html="$t('conditions.info.social')"></p>
	<p v-html="$t('conditions.info.extra')"></p>
	<p v-html="$t('conditions.info.email')"></p>
	<p v-html="$t('conditions.info.phone')"></p>

	<p v-html="$t('conditions.sections.s1.p1')"></p>
	<p v-html="$t('conditions.sections.s1.p2')"></p>

	<p v-html="$t('conditions.sections.s1.p3')"></p>
	<p v-html="$t('conditions.sections.s1.p4')"></p>
	<p v-html="$t('conditions.sections.s1.p5')"></p>

	<h4 v-html="$t('conditions.sections.s1.title')"></h4>

	<p v-html="$t('conditions.sections.s1.p6')"></p>
	<p v-html="$t('conditions.sections.s1.p7')"></p>
	<p v-html="$t('conditions.sections.s1.p8')"></p>
	<p v-html="$t('conditions.sections.s1.p9')"></p>
	<p v-html="$t('conditions.sections.s1.p10')"></p>

	<h4 v-html="$t('conditions.sections.s2.title')"></h4>

	<p v-html="$t('conditions.sections.s2.p1')"></p>
	<p v-html="$t('conditions.sections.s2.p2')"></p>


	<h4 v-html="$t('conditions.sections.s3.title')"></h4>

	<p v-html="$t('conditions.sections.s3.p1')"></p>
	<p v-html="$t('conditions.sections.s3.p2')"></p>
	<p v-html="$t('conditions.sections.s3.p3')"></p>
	<p v-html="$t('conditions.sections.s3.p4')"></p>
	<p v-html="$t('conditions.sections.s3.p5')"></p>
	<p v-html="$t('conditions.sections.s3.p6')"></p>


	<h4 v-html="$t('conditions.sections.s4.title')"></h4>

	<p v-html="$t('conditions.sections.s4.p1')"></p>
	<p v-html="$t('conditions.sections.s4.p2')"></p>
	<p v-html="$t('conditions.sections.s4.p3')"></p>
	<p v-html="$t('conditions.sections.s4.p4')"></p>
	<p v-html="$t('conditions.sections.s4.p5')"></p>
	<p v-html="$t('conditions.sections.s4.p6')"></p>
	

	<h4 v-html="$t('conditions.sections.s5.title')"></h4>

	<p v-html="$t('conditions.sections.s5.p1')"></p>
	<p v-html="$t('conditions.sections.s5.p2')"></p>
	<p v-html="$t('conditions.sections.s5.p3')"></p>
	<p v-html="$t('conditions.sections.s5.p4')"></p>
	<p v-html="$t('conditions.sections.s5.p5')"></p>
	<p v-html="$t('conditions.sections.s5.p6')"></p>
	<p v-html="$t('conditions.sections.s5.p7')"></p>
	<p v-html="$t('conditions.sections.s5.p8')"></p>
	<p v-html="$t('conditions.sections.s5.p9')"></p>
	<p v-html="$t('conditions.sections.s5.p10')"></p>
	<p v-html="$t('conditions.sections.s5.p11')"></p>


	<h4 v-html="$t('conditions.sections.s6.title')"></h4>

	<p v-html="$t('conditions.sections.s6.p1')"></p>
	<p v-html="$t('conditions.sections.s6.p2')"></p>
	<p v-html="$t('conditions.sections.s6.p3')"></p>
	<p v-html="$t('conditions.sections.s6.p4')"></p>
	<p v-html="$t('conditions.sections.s6.p5')"></p>

	<h4 v-html="$t('conditions.sections.s7.title')"></h4>

	<p v-html="$t('conditions.sections.s7.p1')"></p>
	<p v-html="$t('conditions.sections.s7.p2')"></p>
	<p v-html="$t('conditions.sections.s7.p3')"></p>

	<h4 v-html="$t('conditions.sections.s8.title')"></h4>

	<p v-html="$t('conditions.sections.s8.p1')"></p>
	<p v-html="$t('conditions.sections.s8.p2')"></p>
	<p v-html="$t('conditions.sections.s8.p3')"></p>

	<h4 v-html="$t('conditions.sections.s9.title')"></h4>

	<p v-html="$t('conditions.sections.s9.p1')"></p>
	

	<h4 v-html="$t('conditions.sections.s10.title')"></h4>

	<p v-html="$t('conditions.sections.s10.p1')"></p>
	<p v-html="$t('conditions.sections.s10.p2')"></p>
	<p v-html="$t('conditions.sections.s10.p3')"></p>
	<p v-html="$t('conditions.sections.s10.p4')"></p>
	<p v-html="$t('conditions.sections.s10.p5')"></p>
	<p v-html="$t('conditions.sections.s10.p6')"></p>

	<h4 v-html="$t('conditions.sections.s11.title')"></h4>

	<p v-html="$t('conditions.sections.s11.p1')"></p>
	<p v-html="$t('conditions.sections.s11.p2')"></p>
	<p v-html="$t('conditions.sections.s11.p3')"></p>
	<p v-html="$t('conditions.sections.s11.p4')"></p>
	<p v-html="$t('conditions.sections.s11.p5')"></p>

  </div>	

</template>

<script>

	export default {
		name: 'Conditions'
	}

</script>

<style lang="scss">

	.conditions
	{
		padding:40px;
		width: calc(100% - 80px);
		max-width: 1133px;
		margin:70px auto;
		text-align: justify;
		color:$primary-color;

		.slogan
		{
			font-style: normal;
			font-weight: 400;
			font-size: 25px;
			line-height: 25px;
			color:$primary-color;
			margin:0 0 35px 0;
			text-align: center;

			@include respond-above(md)
			{
				display: block;
				
			}

		}

		h4
		{
			margin-top: 40px;
		}

		*
		{
			font-style: normal;
			font-weight: regular;
			font-size: 18px;
			line-height: 20px;
			color:$primary-color;
		}

		table{
			tr
			{
				td, th
				{
					padding:10px;
				}
			}
		}
	}

</style>
